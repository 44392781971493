import React, { useState } from 'react';

import { SimpleItem, Label, RequiredRule } from 'devextreme-react/form';

import { EntityEditPage, EntityFormInlineList, useSvcSelectBoxConfig } from '@/svc';
import { DateTime } from 'luxon';

export default function FormazioneCorsiEditPage() {
  const [, setCurrentDatarow] = useState(void 0);
  const [currentNomePerSito, setCurrentNomePerSito] = useState(void 0);
  const [currentDurataCorso, setCurrentDurataCorso] = useState(void 0);

  const corsiSelectBoxConfig = useSvcSelectBoxConfig({
    domain: 'Xtra',
    entity: 'corsi',
    paginate: true
  });

  const onDatarowReady = (datarow) => {
    setCurrentDatarow(datarow);
    if (datarow.id > 0) {
      setCurrentNomePerSito(
        datarow.corso?.nome_per_sito ?? ' '
      );
      setCurrentDurataCorso(
        datarow.corso?.durata_ore
      );
    }
  }

  const onCorsoChanged = (v) => {
    setCurrentNomePerSito(
      v?.value?.nome_per_sito ?? ' '
    );
    setCurrentDurataCorso(
      v?.value?.durata_ore
    );
  }

  return (
    <>
      <EntityEditPage
        domain='Xtra'
        entity='formazioneCorsi'
        onDatarowReady={onDatarowReady}>
          <EntityEditPage.EditForm>
            {(datarow, setDatarowField) => (
              <React.Fragment>
                <SimpleItem
                  colSpan={4}
                  dataField="corso"
                  editorType="dxSelectBox"
                  editorOptions={{
                    ...corsiSelectBoxConfig,
                    onValueChanged: onCorsoChanged
                  }}>
                    <Label text="Corso" />
                    <RequiredRule />
                </SimpleItem>
                <SimpleItem
                  colSpan={2}
                  editorType="dxTextBox"
                  editorOptions={{
                    readOnly: true,
                    value: currentNomePerSito
                  }}
                  visible={!!currentNomePerSito}
                >
                  <Label text="Nome per il sito" />
                </SimpleItem>
                <SimpleItem
                  colSpan={2}
                  editorType="dxTextBox"
                  editorOptions={{
                    readOnly: true,
                    value: currentDurataCorso
                  }}
                  visible={!!currentDurataCorso}
                >
                  <Label text="Durata corso (in ore)" />
                </SimpleItem>
                <SimpleItem
                  colSpan={4}
                  dataField="descrizione"
                  editorType="dxTextArea"
                >
                  <Label text="Descrizione" />
                </SimpleItem>
                <SimpleItem colSpan={4}>
                  <Label text='Date Formazione' />
                  <EntityFormInlineList
                    items={datarow.dateFormazione}
                    setItems={setDatarowField('dateFormazione')}
                    domain='Xtra'
                    entity='formazioneCorsiDate'
                    formTitle='Aggiungi / modifica data formazione'
                    defaultNewData={{
                      formazioneCorso: {
                        id: datarow.id
                      }
                    }}
                    ListItemComponent={(item) => <span>
                      Dal {DateTime.fromISO(item.data_inizio).toFormat('d/M/yyyy HH:mm')} al {DateTime.fromISO(item.data_fine).toFormat('d/M/yyyy HH:mm')}
                    </span>}
                    FormItems={[
                      <SimpleItem
                        itemLabel='Data (DAL)'
                        dataField='data_inizio'
                        editorType='dxDateBox'
                        editorOptions={{
                          type: 'datetime',
                          dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ssZ'
                        }}
                      />,
                      <SimpleItem
                        itemLabel='Data (AL)'
                        dataField='data_fine'
                        editorType='dxDateBox'
                        editorOptions={{
                          type: 'datetime',
                          dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ssZ'
                        }}
                      />
                    ]}
                  />
                </SimpleItem>
              </React.Fragment>
            )}
          </EntityEditPage.EditForm>
      </EntityEditPage>
    </>
  );
}